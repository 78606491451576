export const technologyIcons = {
  react: () => <div><img align="left" alt="react" src="https://img.shields.io/badge/react%20-%2320232a.svg?&style=for-the-badge&logo=react&logoColor=%2361DAFB" /></div>,
  reactNative: () => <div><img align="left" alt="react native" src="https://img.shields.io/badge/react%20native-%2320232a.svg?&style=for-the-badge&logo=react&logoColor=%2361DAFB" /></div>,
  nodeJS: () => <div><img align="left" alt="nodejs" src="https://img.shields.io/badge/node.js%20-%2343853D.svg?&style=for-the-badge&logo=node.js&logoColor=white" /></div>,
  firebase: () => <div><img align="left" alt="firebase" src="https://img.shields.io/badge/-FIREBASE-blue?style=for-the-badge&logo=firebase" /></div>,
  typescript: () => <div><img align="left" alt="typescript" src="https://img.shields.io/badge/-TYPESCRIPT-262626?style=for-the-badge&logo=typescript" /></div>,
  javascript: () => <div><img align="left" alt="javascript" src="https://img.shields.io/badge/-JAVASCRIPT-262626?style=for-the-badge&logo=javascript" /></div>,
  html: () => <div><img align="left" alt="html" src="https://img.shields.io/badge/-HTML-262626?style=for-the-badge&logo=html5" /></div>,
  css: () => <div><img align="left" alt="css" src="https://img.shields.io/badge/-CSS-262626?style=for-the-badge&logo=csswizardry" /></div>,
  python: () => <div><img align="left" alt="python" src="https://img.shields.io/badge/-PYTHON-262626?style=for-the-badge&logo=python" /></div>,
  adobeXD: () => <div><img align="left" alt="adobexd" src="https://img.shields.io/badge/-ADOBE XD-450134?style=for-the-badge&logo=adobexd" /></div>,
  adobeIllustrator: () => <div><img align="left" alt="adobeillustrator" src="https://img.shields.io/badge/-ADOBE ILLUSTRATOR-300200?style=for-the-badge&logo=adobeillustrator" /></div>,
  swift: () => <div><img align="left" alt="adobeillustrator" src="https://img.shields.io/badge/-SWIFT-300200?style=for-the-badge&logo=swift"/></div>
}