import react from 'react';
import styles from './landingPage.module.css';
import { MdDesignServices, MdOutlineDevices, MdLightbulb } from 'react-icons/md';
import Projects from '../components/projects/projects.js';
import Contact from '../components/contact/contact.js';
import Footer from '../components/footer/footer.js';
import headshot from '../assets/headshot.png';

const skills = [
  { 
    title: 'Mobile & web development',
    icon: () => <MdDesignServices size={45} color={'white'}/>,   
  },
  { 
    title: 'UI/UX Design',
    icon: () => ( <MdOutlineDevices size={45} color={'white'}/>), 
  },
  { 
    title: 'Strategy',
    icon: () => ( <MdLightbulb size={45} color={'white'}/> ), 
    
  }
]
const projects = [
  {title: ""}
]
export default function LandingPage (){
  return(
    <div className={styles.landingPage}>
      <div style={{ 
        padding: '5vw 5vw 0 5vw',
        }}>
        <div className={styles.profileView}>
          <div className={styles.infoContainer}>
            <h1>Hello!</h1>
            <h1>I'm Jack Claxton</h1>
            <h3>Designer &#8226; Developer &#8226; Innovator</h3>
            <p>Versatile and adaptable front-end developer with a background in business possessing strong analytical and problem-solving skills. Embraces teamwork, but also enjoys working independently. Will happily give more, and expect less. Constantly learning new skills to make apps faster, leaner, and cleaner.</p>
          </div>
          <div>
            <img
              className={styles.headshot}
              src={headshot}
              alt={'Headshot'}/>
          </div>
        </div>


        <div className={styles.skillsContainer}>
          {skills.map((skill, index) => {
            return(
              <body>
                <div className={styles.hex}>
                  <div>
                    {skill.icon()}
                  </div>
                </div>
                <p>{skill.title}</p>
              </body>
            )
          })}
        </div>
      </div>
      

      <Projects/>
      {/* <Contact/> */}
      <Footer/>
    </div>
  )
}