import React, { PureComponent } from 'react'

import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import { ExpandMore } from '@mui/icons-material';
import styles from './projects.module.css';
import { technologyIcons } from '../../util/technologyIcons';
import feed from '../../assets/gymBuddy/Feed.png';
import profile from '../../assets/gymBuddy/Profile.png';
import splashScreen from '../../assets/gymBuddy/SplashScreen.png';
import all3 from '../../assets/stoovy/all3.png';
import website from '../../assets/stoovy/website.png';
import img1 from '../../assets/handsOff/img1.png';
import img2 from '../../assets/handsOff/img2.png';
import landingScreen from '../../assets/borrowIt/landingScreen.png';
import messages from '../../assets/borrowIt/messages.png';

const { 
  javascript,
  typescript, 
  reactNative, 
  nodeJS, 
  firebase, 
  adobeIllustrator, 
  adobeXD,
  react,
  swift,
} = technologyIcons;

const projectsInfo = [
  {
    title: "BorrowIt",
    date: "Dec 2021 - Present",
    bullets: [
      "Designing and developing a web app for neighbors to share and borrow items with one another"
    ],
    technologies: () => (
      <div className={styles.technologiesContainer}>
        <h4>Development</h4>
        {react()}
        {typescript()}
        {nodeJS()}
        {firebase()}
        <h4>UI/UX Design</h4>
        {adobeXD()}
      </div>
    ),
    images: [
      { type: landingScreen },
      { type: messages },
    ]
  },
  {
    title: "GymBuddy",
    date: "June 2021 - Sep 2021",
    bullets: [
      "Designed and developed an app for gym-goers to share their workouts with one another"
    ],
    technologies: () => (
      <div className={styles.technologiesContainer}>
        <h4>Development</h4>
        {reactNative()}
        {typescript()}
        {nodeJS()}
        {firebase()}
        <h4>UI/UX Design</h4>
        {adobeXD()}
        <h4>Logo Design</h4>
        {adobeIllustrator()}
      </div>
    ),
    images: [
      // { type: splashScreen },
      { type: feed },
      { type: profile }
    ]
  },
  {
    title: "Stoovy",
    date: "Mar 2018 - May 2021",
    bullets: [
      "Created the company, and the Stoovy mobile app available on the Apple App Store, connecting students to receive last-mile restaurant and grocery deliveries; expanded the offerings to tutoring and custom job services",
      "Developed the web infrastructure and payment processing, managing the frontend and backend of mobile and web applications in React Native, JavaScript, and Node.js",
      "Defined company objectives and conducted meetings with executives, shareholders, investors, and media",
      "Showcased in The Boston Globe and Forbes (“Food delivery right to your dorm door: How some campus dining halls are competing with GrubHub”, “Grocers Beware: Food delivery closing the gap between campus and commerce”)",
    ],
    technologies: () => (
      <div className={styles.technologiesContainer}>
        <h4>Development</h4>
        {reactNative()}
        {react()}
        {javascript()}
        {nodeJS()}
        {firebase()}
        <h4>UI/UX Design</h4>
        {adobeXD()}
      </div>
    ),
    images: [
      { type: all3 },
      { type: website }
    ]
  },
  {
    title: "HandsOff",
    date: "Mar 2020 - May 2020 ",
    bullets: [
      "Amidst the COVID-19 worldwide pandemic, I developed Hands Off, an application for the Apple Watch that reminds users not to touch their face. Developed with Swift using device accelerometer and gyroscope data",
    ],
    technologies: () => (
      <div className={styles.technologiesContainer}>
        <h4>Development</h4>
        {swift()}
      </div>
    ),
    images: [
      { type: img1 },
      { type: img2 }
    ]
  },
  {
    title: "WallFlwr",
    date: "Feb 2019 - May 2019",
    bullets: [
      "Developed and presented extensive business plan focusing on the marketing, operational, financial, and risk mitigation efforts of a fictional startup, “WallFlwr”, over a five-year span",
      "Projected revenue and expenses for the life of the business using survey data to calculate purchase intent, repeat purchases, and awareness from marketing efforts",
    ]
 },
]

export class Projects extends PureComponent {
  render() {
    return (
      <div className={styles.projects}>
        <h1>Projects</h1>
        <div className={styles.projectsContainer}>
          {projectsInfo.map((project, index) => (
            <ProjectCard project={project}/>
          ))}
        </div>
      </div>
    )
  }
}

export default Projects;

class ProjectCard extends PureComponent{
  render(){
    const { title, date, bullets, technologies, images } = this.props.project;
    console.log('images: ', images)
    return(
      <div className={styles.projectCard}>
        <span>
          <h2>{title}</h2>
          <p>{date}</p>
        </span>
        <ul>
          {bullets.map((bullet) => (
            <li>{bullet}</li>
          ))}
        </ul>
        {images &&
          <Accordion sx={{ backgroundColor: 'inherit', boxShadow: 'none'}}>
          <AccordionSummary
            className={styles.accordion}
            expandIcon={<ExpandMore/>}
            aria-controls="panel1a-content"
            id="panel1a-header">
          <p>View more</p>
          </AccordionSummary>
            <AccordionDetails>
              <div className={styles.moreInfoContainer}>
                {technologies && technologies()}
                <div className={styles.photosContainer}>
                  {images?.map((image) => {
                    return <img src={image.type}/>
                  })}
                </div>
              </div>
            </AccordionDetails>
          </Accordion>
        }
      </div>
    )
  }
}