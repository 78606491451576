import React from 'react'
import './footer.css';

function Footer() {
  return (
    <div className={'footer'}>
      <p>&copy; John Claxton 2021</p>
    </div>
  )
}

export default Footer;
