import React, { PureComponent } from 'react'
import './contact.css';

const contactSubtitle = "Reach out to chat with me about new work, opportunities, and cool ideas!";

export class Contact extends PureComponent {
  render() {
    return (
      <div className={'contactView'}>
        <h1>Contact</h1>
        <form>
          <p>{contactSubtitle}</p> 
          <span>
            <input type={'text'} placeholder={'Name*'} required/>
            <input type={'text'} placeholder={'Email*'} required/>
          </span>
          <textarea type={'text'} placeholder={'Message*'} required/>
          <input type={'submit'} title={'Submit'}/>
        </form>
      </div>
    )
  }
}

export default Contact;
