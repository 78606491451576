import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

// import { initializeApp } from "firebase/app";
// import { getAnalytics } from "firebase/analytics";
// const firebaseConfig = {
//   apiKey: "AIzaSyA4Dzm3INsDzjpm1attFmKiOhvHzz3KlVM",
//   authDomain: "jackclaxton-web.firebaseapp.com",
//   databaseURL: "https://jackclaxton-web.firebaseio.com",
//   projectId: "jackclaxton-web",
//   storageBucket: "jackclaxton-web.appspot.com",
//   messagingSenderId: "543376335097",
//   appId: "1:543376335097:web:53c43ae5e323d9c5b98228",
//   measurementId: "G-9GR6QRYXPZ"
// };
// const app = initializeApp(firebaseConfig)
// const analytics = getAnalytics(app);

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
